.heading-and-price-section-div {
  display: flex;
  width: 50%;
  padding: 0 4%;
}

.heading-and-price-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
}

.heading-and-price-section h2 {
  font-family: "Inter", sans-serif;
  font-size: 42px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
  margin-bottom: 14px;
}

.product-price-section {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 14px;
  width: 100%;
}
.product-price-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #373B42;
  margin-bottom: 8px;
}
.grossprofit-con{
  border: solid 1px #cad1d7;
  background-color: #fff;
  padding: 10px 14px;
  border-radius: 8px;
  width: 100%;
  height: 46px;
}
.product-price-row .price-tag {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.product-price-row .price {
  font-family: "Assistant", sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 1.3px;
  line-height: 27px;
  letter-spacing: normal;
  text-align: left;
  color:  rgb(18, 18, 18);
}

.product-price-section .tax {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
 
  letter-spacing: 0.6px;
/* line-height :28.8px; */
  letter-spacing: normal;
  text-align: left;
  color: #5d6c7d;
}
.sale-box{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 35px;
  border-radius: 20px;
  background-color: #121212;
  color: white;
}
.product-add-cart-button {
  height: 42px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 7px 21px;
  border: solid 0.9px #373b42;
  background-color: #fff;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #373b42;
  margin-bottom: 7px;
}

.product-buy-now-button {
  height: 42px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #373b42;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  margin-bottom: 7px;
}

.product-add-cart-button:hover,
.product-buy-now-button:hover {
  opacity: 0.9;
  font-weight: 600;
}

.product-payment-method-box {
  height: 103px;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: solid 0.9px #e3e6ea;
  margin-top: 21px;
  margin-bottom: 21px ;
}
.savings-amount{
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #E22120;
}
.product-method-hd {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #a5afbb;
  margin-top: -12px;
  background-color: #fff;
  padding: 0 12px;
}

.product-payment-method-images-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
}

.guaranted-results-container {
  margin-bottom: 10px;
  width: 100%;
}

.guaranted-results-header {
  height: 54.4px;
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 14.7px 7.3px;
  border-radius: 2px;
  border: solid 1px #e3e6ea;
  background-color: #fff;
  cursor: pointer;
  gap: 25px;
}
.guaranted-results-header-content{
  display: flex;
  gap: 5px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.expanded-content {
  color: #121212;
  background-color: #fff;
  padding: 10px;
  transition: height 0.3s ease-in-out;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.05);
}

.product-shipping-range {
  height: 54.4px;
  align-self: stretch;
  flex-grow: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 14.7px 7.3px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}
.product-shipping-range span{
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #373b42;
}

.strikethrough {
    text-decoration: line-through;
    color: grey;
  }
  